<!-- Router outlet -->
<router-outlet></router-outlet>

<!-- Confirmation dialog -->
<app-confirmation></app-confirmation>

<!-- Toast component -->
<app-toast></app-toast>

<!-- Loader -->
<app-loader></app-loader>