import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
	{
		name: 'Dashboard',
		url: ['/', 'dashboard'],
		icon: 'icon-speedometer',
	},
	{
		name: 'Support',
		url: ['/', 'support'],
		icon: 'bi bi-headset',
	},
	{
		name: 'Box',
		url: ['/', 'gyms'],
		icon: 'bi bi-building',
	},
	{
		name: 'Abbonamenti Cross-In',
		icon: 'bi bi-receipt',
		children: [
			{
				name: 'Piani di abbonamento',
				url: ['/', 'backend-subscriptions'],
				icon: 'bi bi-receipt',
			},
			{
				name: 'Abbonamenti',
				url: ['/', 'gym-subscriptions'],
				icon: 'bi bi-currency-dollar',
			}
		]
	}
];
