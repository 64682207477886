<app-header [navbarBrandRouterLink]="['/', 'dashboard']" [fixed]="true"
	[navbarBrandFull]="{src: 'assets/img/logo_black.png', width: 89, height: 25, alt: 'Crossin Logo'}"
	[navbarBrandMinimized]="{src: 'assets/img/logo_black.png', width: 30, height: 30, alt: 'Crossin Logo'}"
	[sidebarToggler]="'lg'" [asideMenuToggler]="false" [mobileAsideMenuToggler]="false">
	<ul class="nav navbar-nav mobile position-absolute" style="left:0; right: 0; z-index: -1; justify-content: center;">
		<li class="nav-item">
			<img src="assets/img/logo_black.png" style="height: 30px">
		</li>
	</ul>
	<ul class="nav navbar-nav desktop">
		<li class="nav-item">
			<img src="assets/img/logo_black.png" style="height: 30px;">
		</li>
	</ul>
	<ul class="nav navbar-nav ml-auto justify-content-end">
		<li class="nav-item dropdown" dropdown placement="bottom right" *ngIf="admin">
			<a class="nav-link img-user" href="#" role="button" aria-haspopup="true" aria-expanded="false"
				dropdownToggle (click)="false">
				<img [src]="admin?.avatar_url || 'assets/img/logo.png'" class="img-avatar" [alt]="admin.email">
			</a>
			<div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
				<a class="dropdown-item" (click)="logout()">
					<i class="fa fa-lock"></i>
					Logout
				</a>
			</div>
		</li>
	</ul>
</app-header>
<div class="app-body">
	<app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
		(minimizedChange)="toggleMinimize($event)" *ngIf='navItems'>
		<app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized">
		</app-sidebar-nav>
		<app-sidebar-minimizer></app-sidebar-minimizer>
	</app-sidebar>
	<main class="main">
		<nav *ngIf="backEnabled">
			<span class="cursor-pointer" (click)="location.back()">
				<i class="bi bi-arrow-left mr-1"></i>
				Torna
			</span>
		</nav>
		<div class="container-fluid pt-4">
			<router-outlet></router-outlet>
		</div>
	</main>
</div>
<app-footer class="d-flex justify-content-between">
	<span><a href="https://cross-in.com/">Cross In </a> &copy; {{ moment().format('YYYY') }}</span>
	<span>Powered by <a href="https://camacrea.it">Camacrea</a></span>
	<span *ngIf="version">Version {{ version }}</span>
</app-footer>