
export enum Requests {
	login,
	getSupportRequests,
	downloadSupportRquestAttachments,
	createSupportRequest,
	uploadSupportAttachment,
	updateSupportRequest,
	getSupportRequest,
	getGyms,
	createGym,
	updateGym,
	getGym,
	deleteGym,
	getStatistics,
	getGymTypes,
	getPrivatePurchasableBackendSubscriptions,
	getBackendSubscriptions,
	deleteBackendSubscription,
	getBackendSubscirption,
	updateBackendSubscirption,
	createBackendSubscirption,
	getTimeUnits,
	getGymSubscriptions,
	deleteGymSubscription,
	getGymSubscirption,
	updateGymSubscirption,
	createGymSubscirption,
	getInvoices,
	getInvoice,
	getPaymentMethods,
	updateInvoice
}
