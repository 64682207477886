import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	AppAsideModule,
	AppBreadcrumbModule,
	AppFooterModule,
	AppHeaderModule,
	AppSidebarModule
} from '@coreui/angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ChartsModule } from 'ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ToastComponent } from './components/toast/toast.component';
import { BackgroundImageDirective } from './directives/background-image.directive';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { SessionInterceptor } from './interceptors/session.interceptor';
import { BaseComponent } from './modules/base/base.component';
import { P404Component } from './modules/error/404.component';
import { P500Component } from './modules/error/500.component';
import { LoginComponent } from './modules/login/login.component';

@NgModule({
	declarations: [
		AppComponent,
		BaseComponent,
		BackgroundImageDirective,
		P404Component,
		P500Component,
		LoginComponent,
		ToastComponent,
		LoaderComponent,
		ConfirmationComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		HttpClientModule,
		AppAsideModule,
		AppBreadcrumbModule.forRoot(),
		AppHeaderModule,
		AppFooterModule,
		AppSidebarModule,
		PerfectScrollbarModule,
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),
		ModalModule.forRoot(),
		ChartsModule,
		ReactiveFormsModule,
		AngularSvgIconModule.forRoot()
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: SessionInterceptor,
			multi: true,
		}
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
