<div class="app-body" [appBackgroundImage]="'assets/img/bg_login.png'">
	<main class="main d-flex">
		<div class="container">
			<div class="row justify-content-md-center">
				<div class="img-logo col-md-9 p-5">
					<img src="assets/img/logo_bianco.png">
				</div>
			</div>
			<div class="row justify-content-md-center">
				<div class="login col-md-5 p-0">
					<div class="card-group">
						<div class="card p-2">
							<div class="card-body">
								<form [formGroup]="form" (submit)='login($event)'>
									<div class="box-top">
										<h1>Login</h1>
										<p class="text-muted">Entra nel tuo account</p>
										<div class="input-group mb-3">
											<div class="input-group-prepend">
												<span class="input-group-text"><i class="icon-user"></i></span>
											</div>
											<input type="text" class="form-control"
												[ngClass]="{ 'is-invalid': !!form.get('login')?.errors }"
												placeholder="Email" formControlName='login' autocomplete="email">
										</div>
										<div class="input-group mb-4">
											<div class="input-group-prepend">
												<span class="input-group-text"><i class="icon-lock"></i></span>
											</div>
											<input type="password" class="form-control"
												[ngClass]="{ 'is-invalid': !!form.get('password')?.errors }"
												placeholder="Password" formControlName='password'
												autocomplete="current-password">
										</div>
									</div>
									<div class="box-bottom">
										<input type="submit" class="btn btn-primary px-4" value="Login">
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</div>