import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import packageJson from 'package.json';
import { filter } from 'rxjs';
import { User } from 'src/app/interfaces/user';
import { AdminService } from 'src/app/services/admin/admin.service';
import { navItems } from '../_nav';

@Component({
	selector: 'app-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

	/**
	 * Logged admin
	 */
	admin?: User;

	sidebarMinimized = true;
	navItems = navItems;

	/**
	 * Version
	 */
	version = packageJson.version;

	/**
	 * Expose moment
	 */
	moment = moment;

	/**
	 * Is the go back button visible
	 */
	backEnabled = false;

	constructor(
		private adminService: AdminService,
		public location: Location,
		public route: ActivatedRoute,
		private router: Router
	) { }

	ngOnInit(): void {
		this.admin = this.adminService.get();

		/**
		 * Set route relative attributes
		 */
		const setRouteAttributes = (): void => {
			const data = this.getRouteData();
			this.backEnabled = !!data.backEnabled;
		};

		/**
		 * On route change
		 */
		setRouteAttributes();
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe({
				next: event => {
					setRouteAttributes();
				}
			});
	}

	toggleMinimize(e: boolean): void {
		this.sidebarMinimized = e;
	}

	logout(): void {
		this.adminService.logout();
	}

	/**
	 * Get activated route's data
	 */
	private getRouteData(route = this.route): Data {
		return route.children[0] ? this.getRouteData(route.children[0]) : (route.snapshot?.data || {});
	}
}
