import { Injectable, Injector, NgZone } from '@angular/core';
import {
	HttpEvent, HttpRequest, HttpHandler,
	HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AdminService } from '../services/admin/admin.service';
import { ToastService } from '../services/toast/toast.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

	constructor(
		private adminService: AdminService,
		private toast: ToastService,
		private injector: Injector
	) { }

	/**
	 * Bail out when session timeout
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			// No retry
			retry(0),
			catchError((error: HttpErrorResponse) => {

				/**
				 * Session expired?
				 */
				if (error?.error?.code === 2) {
					this.adminService.logout();
					this.toast.error('Sessione scaduta');
				}

				return throwError(error);
			})
		);
	}
}
