import { HttpErrorResponse } from '@angular/common/http';
import {
	Component,
	OnInit
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin/admin.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	/**
	 * Form controller (validates and passes values to the submit method)
	 */
	form = this.fb.group({
		login: null,
		password: null
	});

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private adminService: AdminService,
		private toast: ToastService,
		private loader: LoaderService,
	) { }

	ngOnInit(): void {

		/**
		 * Rdirect if authenticated
		 */
		if (this.adminService.isAuthenticated()) {
			this.router.navigateByUrl('/dashboard');
		}
	}

	/**
	 * Login
	 */
	login(event: Event): void {
		event.preventDefault();
		this.toast.hide();

		/**
		 * Go to DashBoard/ Home
		 */
		this.loader.show();
		this.adminService.login(
			this.form.get('login')?.value,
			this.form.get('password')?.value,
		).then(() => {
			this.loader.hide();

			/**
			 * Redirect dashboard
			 */
			this.router.navigateByUrl('/dashboard');

		}).catch((error: HttpErrorResponse) => {
			this.loader.hide();

			/**
			 * Error catch
			 */
			switch (error?.error?.code) {
				case 2: break;
				case 11: break;
				case 12: break;
				case 1:
				case 10:
					this.toast.error('Questa utenza non è valida per questa piattarfoma');
					break;
				case 3:
					this.toast.error('Errore di validazione assicurati di aver aver inserito la tua mail e password corretamente');
					for (const key in error.error.validation) {
						if (Object.prototype.hasOwnProperty.call(error.error.validation, key)) {
							this.form.get(key)?.setErrors({
								required: true
							});
						}
					}
					break;
				default:
					this.toast.error('Errore sconosciuto se l errore persiste contattare l administratore');
					console.error(error);
					break;
			}
		});
	}
}
