import {
	NgModule
} from '@angular/core';
import {
	RouterModule, Routes
} from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';
import { BaseComponent } from './modules/base/base.component';
import { P404Component } from './modules/error/404.component';
import { P500Component } from './modules/error/500.component';
import { LoginComponent } from './modules/login/login.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full',
	},
	{
		path: '404',
		component: P404Component,
	},
	{
		path: '500',
		component: P500Component,
	},
	{
		path: 'login',
		component: LoginComponent,
	},
	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('./modules/base/dashboard/dashboard.module').then(m => m.DashboardModule)
			},
			{
				path: 'support',
				loadChildren: () => import('./modules/base/support/support.module').then(m => m.SupportModule)
			},
			{
				path: 'gyms',
				loadChildren: () => import('./modules/base/gyms/gyms.module').then(m => m.GymsModule)
			},
			{
				path: 'backend-subscriptions',
				loadChildren: () => import('./modules/base/backend-subscriptions/backend-subscriptions.module').then(m => m.BackendSubscriptionsModule)
			},
			{
				path: 'gym-subscriptions',
				loadChildren: () => import('./modules/base/gym-subscriptions/gym-subscriptions.module').then(m => m.GymSubscriptionsModule)
			}
		]
	},
	{
		path: '**',
		component: P404Component
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
