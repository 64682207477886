import {
	Component,
	OnInit
} from '@angular/core';
import {
	Event, NavigationStart, Router
} from '@angular/router';
import moment from 'moment';
import { filter } from 'rxjs/operators';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'body',
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
	constructor(
		private router: Router,
	) { }

	ngOnInit(): void {

		/**
		 * On route change
		 */
		this.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe((event: Event) => {

			/**
			 * Scroll up on page change
			 */
			window.scrollTo(0, 0);
		});

		/**
		 * Set moment locale
		 */
		moment.locale('it');
	}
}
