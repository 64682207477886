import {
	Component, OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationActionTypes } from 'src/app/enums/confirmation-action-types.enum';
import { ConfirmationButtonTypes } from 'src/app/enums/confirmation-button-types.enum';
import { ConfirmationButton, ConfirmationData } from 'src/app/interfaces/confirmation';
import { ConfirmationService } from 'src/app/services/confirmation/confirmation.service';

@Component({
	selector: 'app-confirmation',
	templateUrl: './confirmation.component.html',
	styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

	/**
	 * Modal template
	 */
	@ViewChild('template') template!: TemplateRef<BsModalRef>;

	/**
	 * Modal reference
	 */
	modalRef!: BsModalRef;

	/**
	 * Text of the confirmation popup
	 */
	data?: ConfirmationData;
	title?: string;
	message?: string;

	/**
	 * Some enums
	 */
	enums = {
		ButtonTypes: ConfirmationButtonTypes,
	};

	constructor(
		private modalService: BsModalService,
		private confirmationService: ConfirmationService,
	) { }


	ngOnInit(): void {
		this.confirmationService.subscribe((data: ConfirmationData) => {
			this.data = data;
			this.modalRef = this.modalService.show(this.template);
		});
	}
	/**
	 * On button click
	 */
	onClick(action: ConfirmationButton): void {

		/**
		 * If is close type
		 */
		if (action.callback === ConfirmationActionTypes.Close) {
			return this.close();
		}

		/**
		 * Call callback
		 */
		if (action.callback) {
			action.callback();
		}

		/**
		 * Check if prevent close
		 */
		if (!action.preventClose) {
			this.close();
		}
	}

	/**
	 * Close popup
	 */
	close(): void {
		this.modalRef.hide();
	}
}
