import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageTypes } from 'src/app/enums/local-storage-types.enum';
import { User } from 'src/app/interfaces/user';
import { HttpService } from '../http/http.service';
import { Requests } from 'src/app/enums/requests.enum';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
	providedIn: 'root'
})
export class AdminService {

	constructor(
		private localStorageService: LocalStorageService,
		private router: Router,
		private http: HttpService,
	) { }

	/**
	 * Get login
	 */
	login(user: string, password: string): Promise<void> {
		return new Promise((resolve: () => void, reject: (error: HttpErrorResponse) => void) => {
			this.http.send(Requests.login, {
				body: {
					login: user,
					password,
				}
			})
				.then((data: {user: User; token: string}) => {

					/**
					 * Set local storage data
					 */
					this.localStorageService.setItem(LocalStorageTypes.AuthToken, data.token);
					this.localStorageService.setItem(LocalStorageTypes.Admin, data.user);

					resolve();
				})
				.catch((error: HttpErrorResponse) => reject(error));
		});
	}

	/**
	 * Get logout
	 */
	logout(): void {

		/**
		 * Delete data from LocalStorage
		 */
		this.localStorageService.removeItem(LocalStorageTypes.AuthToken);
		this.localStorageService.removeItem(LocalStorageTypes.Admin);

		/**
		 * Redirect
		 */
		this.router.navigateByUrl('/');
	}

	/**
	 * Ritorno il token di accesso dell'utente loggato
	 */
	getAuthToken(): void {
		return this.localStorageService.getItem(LocalStorageTypes.AuthToken);
	}

	/**
	 *	Is the admin authenticated
	 */
	isAuthenticated(): boolean {
		return this.localStorageService.getItem(LocalStorageTypes.AuthToken) != null;
	}

	/**
	 * Get logged admin
	 */
	get(): User {
		return this.localStorageService.getItem(LocalStorageTypes.Admin);
	}
}
