<div class="app flex-row align-items-center">
	<div class="container">
		<div class="row justify-content-center">
			<div class="col-md-6">
				<div class="clearfix">
					<h1 class="float-left display-3 mr-4">404</h1>
					<h4 class="pt-3">OOPS! YOU'RE LOST.</h4>
					<p class="text-muted">THE PAGE YOU ARE LOOKING FOR WAS NOT FOUND. </p>
				</div>
			</div>
		</div>
	</div>
</div>