import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Requests } from 'src/app/enums/requests.enum';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader/loader.service';

interface Request {
	url: string;
	method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'; // Carefull with put since laravel can't read it
	responseType?: 'text' | 'json' | 'arraybuffer' | 'blob' | undefined;
	external?: boolean;
	observeHeaders?: boolean;
};

interface RequestOptions {
	body?: { [key: string]: any };
	urlParams?: { [key: string]: string };
	queryParams?: { [key: string]: string };
	headers?: { [key: string]: string };
};

@Injectable({
	providedIn: 'root'
})
export class HttpService {

	/**
	 * Conversion table to pass from Requests enum to Request object
	 */
	private readonly conversionTable: { enum: Requests; object: Request }[] = [
		{
			enum: Requests.login,
			object: {
				url: '/login',
				method: 'POST'
			}
		},
		{
			enum: Requests.getSupportRequests,
			object: {
				url: '/support-requests',
				method: 'GET'
			}
		},
		{
			enum: Requests.downloadSupportRquestAttachments,
			object: {
				url: '/support-requests/{id}/download-attachments',
				method: 'GET',
				responseType: 'text'
			}
		},
		{
			enum: Requests.uploadSupportAttachment,
			object: {
				url: '/support-requests/upload-attachment',
				method: 'POST'
			}
		},
		{
			enum: Requests.createSupportRequest,
			object: {
				url: '/support-requests',
				method: 'POST'
			}
		},
		{
			enum: Requests.updateSupportRequest,
			object: {
				url: '/support-requests/{id}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.getSupportRequest,
			object: {
				url: '/support-requests/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getGyms,
			object: {
				url: '/gyms',
				method: 'GET'
			}
		},
		{
			enum: Requests.createGym,
			object: {
				url: '/gyms',
				method: 'POST'
			}
		},
		{
			enum: Requests.updateGym,
			object: {
				url: '/gyms/{id}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.getGym,
			object: {
				url: '/gyms/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.deleteGym,
			object: {
				url: '/gyms/{id}',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.getStatistics,
			object: {
				url: '/statistics',
				method: 'POST'
			}
		},
		{
			enum: Requests.getGymTypes,
			object: {
				url: '/gym/types',
				method: 'GET'
			}
		},
		{
			enum: Requests.getPrivatePurchasableBackendSubscriptions,
			object: {
				url: '/backend-subscriptions/private-purchasable',
				method: 'GET'
			}
		},
		{
			enum: Requests.getBackendSubscriptions,
			object: {
				url: '/backend-subscriptions',
				method: 'GET'
			}
		},
		{
			enum: Requests.getBackendSubscirption,
			object: {
				url: '/backend-subscriptions/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.updateBackendSubscirption,
			object: {
				url: '/backend-subscriptions/{id}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.createBackendSubscirption,
			object: {
				url: '/backend-subscriptions',
				method: 'POST'
			}
		},
		{
			enum: Requests.getTimeUnits,
			object: {
				url: '/time-units',
				method: 'GET'
			}
		},
		{
			enum: Requests.getGymSubscriptions,
			object: {
				url: '/gym-subscriptions',
				method: 'GET'
			}
		},
		{
			enum: Requests.deleteGymSubscription,
			object: {
				url: '/gym-subscriptions/{id}',
				method: 'DELETE'
			}
		},
		{
			enum: Requests.getGymSubscirption,
			object: {
				url: '/gym-subscriptions/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.updateGymSubscirption,
			object: {
				url: '/gym-subscriptions/{id}',
				method: 'PUT'
			}
		},
		{
			enum: Requests.createGymSubscirption,
			object: {
				url: '/gym-subscriptions',
				method: 'POST'
			}
		},
		{
			enum: Requests.getInvoices,
			object: {
				url: '/invoices',
				method: 'GET'
			}
		},
		{
			enum: Requests.getInvoice,
			object: {
				url: '/invoices/{id}',
				method: 'GET'
			}
		},
		{
			enum: Requests.getPaymentMethods,
			object: {
				url: '/payment-methods',
				method: 'GET'
			}
		},
		{
			enum: Requests.updateInvoice,
			object: {
				url: '/invoices/{id}',
				method: 'PUT'
			}
		}
	];

	constructor(
		private http: HttpClient,
		private loader: LoaderService
	) { }

	/**
	 * Send http request to server
	 */
	send(request: Requests, options?: RequestOptions): Promise<any> {
		return new Promise((resolve: (response: any) => void, reject: (error?: HttpErrorResponse) => void) => {

			/**
			 * Convert the request from enum to object
			 */
			const requestObj = this.resolveRequest(request);

			/**
			 * Reject if cast unsuccsessfull
			 */
			if (!requestObj) {
				console.error('This request does not exist!');
				reject();
			} else {

				/**
				 * Replace params in the url
				 */
				for (const key in options?.urlParams) {
					if (Object.prototype.hasOwnProperty.call(options?.urlParams, key)) {
						requestObj.url = requestObj.url.replace(`{${key}}`, options?.urlParams[key] || '');
					}
				}

				/**
				 * Append query params to the url
				 */
				if (options?.queryParams) {

					/**
					 * Start the query params
					 */
					requestObj.url += '?';
					for (const key in options?.queryParams) {
						if (Object.prototype.hasOwnProperty.call(options?.queryParams, key)) {
							requestObj.url += `${key}=${options?.queryParams[key]}&`;
						}
					}

					/**
					 * Remove trailing &
					 */
					requestObj.url = requestObj.url.replace(/\&$/, '');
				}

				/**
				 * If url is not external
				 */
				if (!requestObj.external) {
					requestObj.url = this.getBaseUrl() + requestObj.url;
				}

				/**
				 * Add headers if requested
				 */
				const headers = new HttpHeaders(options?.headers || {});

				/**
				 * If the request is a PUT with a FormData instance
				 */
				if (requestObj.method === 'PUT' && options?.body instanceof FormData) {

					/**
					 * * Set the put method in the form data and fire a post instead
					 * * PHP put method can't process form data so we need to do this work around
					 */
					options?.body.append('_method', 'PUT');
					requestObj.method = 'POST';
				}

				/**
				 * HTTP Request
				 */
				this.http.request(requestObj.method as string, requestObj.url, {
					responseType: requestObj.responseType || 'json',
					body: options?.body,
					observe: requestObj.observeHeaders ? 'response' : 'body',
					headers
				}).subscribe(
					(response: any | HttpResponse<any>) => resolve(response),
					(error: HttpErrorResponse) => {
						this.loader.hide();
						reject(error);
					},
				);
			}
		});
	}

	/**
	 * Get url of the requested request
	 */
	getUrl(request: Requests, options?: RequestOptions): string | null {

		/**
		 * Convert the request from enum to object
		 */
		const requestObj = this.resolveRequest(request);

		/**
		 * Reject if cast unsuccsessfull
		 */
		if (!requestObj) {
			console.error('This request does not exist!');
			return null;
		} else {

			/**
			 * Replace params in the url
			 */
			for (const key in options?.urlParams) {
				if (Object.prototype.hasOwnProperty.call(options?.urlParams, key)) {
					requestObj.url = requestObj.url.replace(`{${key}}`, options?.urlParams[key] || '');
				}
			}

			/**
			 * Append query params to the url
			 */
			if (options?.queryParams) {

				/**
				 * Start the query params
				 */
				requestObj.url += '?';
				for (const key in options?.queryParams) {
					if (Object.prototype.hasOwnProperty.call(options?.queryParams, key)) {
						requestObj.url += `${key}=${options?.queryParams[key]}&`;
					}
				}

				/**
				 * Remove trailing &
				 */
				requestObj.url = requestObj.url.replace(/\&$/, '');
			}

			/**
			 * If url is not external
			 */
			if (!requestObj.external) {
				requestObj.url = this.getBaseUrl() + requestObj.url;
			}

			return requestObj.url;
		}
	}

	/**
	 * Converts the requests string from the enum to a Request object
	 */
	private resolveRequest(request: Requests): Request | undefined {

		/**
		 * Find it in the conveersion table and return the object
		 */
		return Object.assign({}, this.conversionTable.find(item => item.enum === request)?.object);
	}

	/**
	 * Get base url
	 */
	private getBaseUrl(): string {
		return environment.apiUrl;
	}
}
